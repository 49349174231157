<template>
  <div>
    <Steps class="p-t-10" :current="current">
      <Step v-for="(step,i) in steps" :key="i" :title="step.title" :content="step.desc"></Step>
    </Steps>

    <component :is="component" :params="params" :isPlatform="true"
        :stepType="2" @next-step="handleNextStep" @prev-step="handlePrevStep"
        @on-submit="handleSubmit"></component>
  </div>
</template>

<script>
import basic from '@/components/contract/contractDetail/edit/Basic'
import price from '@/components/contract/contractDetail/edit/Price'
import commission from '@/components/contract/contractDetail/edit/Commission'
import confirmChange from '@/components/contract/contractDetail/edit/ConfirmChange'
import upload from '@/components/contract/contractDetail/edit/Upload'

import { checkContractDetail } from '@/api/scp/contract'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    },
    otherParams: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      contractId: this.baseId,
      versionId: this.otherParams,
      current: 0,
      contract: {
        contractName: '',
        contractCategory: 1,
        advertiserId: 0,
        mark: '',
        firstParty: {},
        payer: {},
        orders: [],
        priceOrder: [],
        payments: [],
        isStandard: true,
        kpiType: 0,
        userId: 0,
        companyId: 0,
        advertiserResultBean: {},
        id: 0,
        versionId: 0,
        newVersionId: 0,
        submitParams: {},
        schedulePrice: []
      },
      component: '',
      params: {},
      id: 0
    }
  },
  components: {
    basic, price, commission, confirmChange, upload
  },
  methods: {
    initPageData () {
      this.id = this.contractId

      checkContractDetail({ contractId: this.id }).then(res => {
        this.contract = res
        this.contract.newVersionId = this.versionId
        this.contract.firstParty = res.customerInfoBean
        this.contract.payments = res.paymentBeanList.map(item => {
          item.money = item.amount
          return item
        })
        this.contract.companyId = this.$store.getters.token.userInfo.companyId
        this.contract.publisherId = this.$store.getters.token.userInfo.publisherId
        this.contract.id = this.id
        this.params = this.contract
        this.component = 'basic'
      })
    },
    handleNextStep (data) {
      const step = this.steps[this.current]
      switch (step.name) {
        case 'basic':
          this.contract.contractName = data.contractName
          this.contract.mark = data.basic.mark
          this.contract.firstParty = data.firstParty
          break
        case 'price':
          this.contract.submitParams = data.submitContract
          this.contract.orders = data.orders
          this.contract.priceOrder = data.priceOrder
          this.contract.payments = data.payments
          this.contract.amount = data.totalMoney
          this.contract.schedulePrice = data.schedulePrice
          break
        case 'commission':
          // this.contract.kpiType = data
          break
        default:
          break
      }

      this.current++
    },
    handlePrevStep (data) {
      this.current--
    },
    handleSubmit () {
      this.$emit('on-submit')
    }
  },
  watch: {
    current: function () {
      this.component = this.steps[this.current].name
      this.params = this.contract
    },
    versionId (val) {
      this.initPageData()
    }
  },
  created () {
    this.initPageData()
  },
  computed: {
    steps: function () {
      const allowCommission = this.$store.getters.token.userInfo.companyPerformance ? 0 : 1
      const steps = [
        { title: '基础信息', desc: '配置合同基础信息', name: 'basic' },
        { title: '付款项', desc: '配置订单付款项、合同付款时间', name: 'price' },
        { title: '绩效预览', desc: '根据合同的最终执行价，预览激励绩效', name: 'commission', allows: [0] },
        { title: '提交附件', desc: '如果需要，提交一些附件', name: 'upload' },
        { title: '确认变更信息', desc: '确认合同全部信息，并提交合同', name: 'confirmChange' }
      ]

      return steps.filter(x => !x.allows || x.allows.includes(allowCommission))
    }

  }

}
</script>
